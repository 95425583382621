<template>
  <div id="userlist">
    <!-- 头部 -->
    <header>
      <div class="header-L">
        <!-- 查询 -->
        <el-input
          v-model="kw"
          placeholder="查询（ 用户名 / 手机号 ）"
          @input="kwChange"
          class="mr20"
        />
        <!-- 筛选用户类型 -->
        <el-select
          v-model="selUserAuth"
          placeholder="请选择用户类型"
          @change="selUserAuthChange"
        >
          <el-option label="全部用户" value=""> </el-option>
          <el-option
            v-for="item in ObjToOpt(userAuthObj)"
            :key="item.val"
            :label="item.lab"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-R"></div>
    </header>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%"
      @row-click="rowClick"
    >
      <el-table-column prop="userName" label="用户名" align="center" />
      <el-table-column prop="userNickName" label="昵称" align="center" />
      <el-table-column prop="userPhone" label="手机号" align="center" />
      <el-table-column
        prop="userAuth"
        label="用户权限"
        width="120"
        align="center"
        :formatter="changeUserAuthObj"
      />
      <el-table-column
        prop="createdAt"
        label="创建日期"
        width="120"
        align="center"
        :formatter="changeDate"
      />
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click.stop="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click.stop="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <footer>
      <el-pagination
        background
        :total="total"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </footer>
    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      :title="formTitle"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="form" :disabled="formDisabled">
        <!-- 用户名 -->
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.userName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 昵称 -->
        <el-form-item label="昵称" :label-width="formLabelWidth">
          <el-input v-model="form.userNickName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item label="手机号" :label-width="formLabelWidth">
          <el-input v-model="form.userPhone" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 用户权限 -->
        <el-form-item label="用户权限" :label-width="formLabelWidth">
          <el-select v-model="form.userAuth" placeholder="请选择">
            <el-option
              v-for="item in ObjToOpt(userAuthObj)"
              :key="item.val"
              :label="item.lab"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!formDisabled">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveList">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateUserInfo,
  pageUserList,
  deleteUserList,
} from "@/api/setting/userlist.js";
import { userAuthObj } from "@/db/setting/userlist";
import { formatterDate, ObjToOpt } from "@/utils/utils.js";
export default {
  data() {
    return {
      ObjToOpt,
      userAuthObj, //用户权限对象
      kw: "", //查询条件
      selUserAuth: "", //筛选用户权限类型
      tableData: [], //表格数据
      page: 1,
      total: null, //总条数
      formTitle: "", //弹框用户名
      dialogFormVisible: false, //弹框开关
      formDisabled: false, //表单禁用开关
      form: {
        userId: "", //用户ID
        userName: "", //用户名
        userNickName: "", //昵称
        userPhone: "", //手机号
        userAuth: "", //用户权限
      },

      formLabelWidth: "120px", //输入框宽度
    };
  },
  mounted() {
    this.pageUserList();
  },
  methods: {
    // 【转换】日期
    changeDate(row, column, cellValue) {
      return formatterDate(cellValue, "yyyy-MM-dd");
    },
    // 【转换】用户权限
    changeUserAuthObj(row, column, cellValue) {
      return this.userAuthObj[cellValue];
    },

    // 【请求】分页任务列表
    pageUserList() {
      let data = {
        page: this.page,
        userAuth: this.selUserAuth,
        kw: this.kw,
      };
      pageUserList(data).then((res) => {
        this.tableData = res.data;
        this.total = res.total;
      });
    },

    // 【监听】模糊查询
    kwChange: _.debounce(function () {
      this.pageUserList();
    }, 500),

    // 【监听】筛选用户权限
    selUserAuthChange() {
      this.pageUserList();
    },

    // 【监听】分页点击事件
    handleCurrentChange(val) {
      this.page = val;
      this.pageUserList();
    },

    // 【监听】行点击事件
    rowClick(row, column, event) {
      this.formTitle = "查看用户";
      this.dialogFormVisible = true;
      this.formDisabled = true;
      this.form = row;
    },

    // 【监听】编辑按钮
    handleEdit(index, row) {
      this.formTitle = "编辑用户";
      this.dialogFormVisible = true;
      this.formDisabled = false;
      row.userAuth = row.userAuth.toString();
      this.form = row;
    },

    // 【监听】弹框表单提交
    saveList() {
      let data = this.form;
      updateUserInfo(data).then((res) => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.dialogFormVisible = false;
          this.pageUserList();
        }
      });
    },

    // 【监听删除】
    handleDelete(index, row) {
      let _this = this;
      this.$confirm("确定要删除该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            userId: row.userId,
          };
          deleteUserList(data).then((res) => {
            if (res.success) {
              _this.$message({
                message: res.msg,
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.pageUserList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#userlist {
  padding: 24px;
  box-sizing: border-box;
}

header {
  height: 0.6rem;
  padding: 0.1rem 0;
  display: flex;
  justify-content: space-between;
}

.header-L {
  display: flex;
}

footer {
  margin: 0.1rem 0;
}
</style>
